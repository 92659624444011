import * as React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';

import Layout from '../components/layout';

import * as shopStyles from '../styles/shop.module.css'

const Shop = () => {

  return (
    <Layout>
      <main className={shopStyles.main}>
        <h1 className={shopStyles.header}>Shop coming soon!</h1>
        <p className={shopStyles.description}>Stay updated by following my social media</p>
        <div className={shopStyles.socialLinkContainer}>
            <a 
                aria-label="Instagram"
                href="https://instagram.com/andreartvegas"
                target="_blank" 
                className={shopStyles.socialLink}
                style={{
                    color: `#d73462`
                }}
                rel="noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
        </div>
      </main>
    </Layout>
  )
}

export default Shop;
